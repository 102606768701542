var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showPage)?_c('b-container',{attrs:{"fluid":""}},[_c('BreadCrumb',{attrs:{"items":[
      {
        text: 'Inicio',
        href: '/'
      }, {
        text: 'Detalhes Estabalecimento',
        active: false,
      }]}}),_c('Header',{attrs:{"merchant-data":{
      tax_id: _vm.fetchData.tax_id,
      name: _vm.fetchData.legal_tax_name,
      active: _vm.fetchData.is_active
    }},on:{"click-historic-button":_vm.getHistoric}}),_c('b-row',[_c('b-col',{attrs:{"md":"7","lg":"9"}},[_c('OptIn',{attrs:{"data":_vm.optInsItems,"merchant-id":+_vm.id.id}}),_c('Endorsement',{attrs:{"data":_vm.endorsementsItems,"merchant-id":+_vm.id.id,"has-valide-opt-in":_vm.hasValidOptiIn}})],1),_c('b-col',{attrs:{"md":"5","lg":"3"}},[_c('time-line',{attrs:{"items":_vm.fetchData.time_line}})],1)],1),_c('Historic',{ref:"historic"},[(_vm.records)?_c('b-table',{staticClass:"text-center",attrs:{"items":_vm.records.data,"fields":[
        { key: 'user', label: 'Usuário'},
        { key: 'action_type', label: 'Ação'},
        { key: 'created_at', label: 'Quando'}
      ]},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(user)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user.name)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.created_at))+" ")]}},{key:"row-details",fn:function(row){return [(row.item.action_type !== 'Inativação de estabelecimento')?_c('b-row',{staticClass:"justify-content-around"},[_c('b-col',{attrs:{"md":"4"}},[_c('div',[_c('h6',[_vm._v(" Email: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.email))])])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Razão Social: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.legal_tax_name))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Nome: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.name))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" CNPJ: ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("tax_id")(row.item.tax_id)))])])],1):_vm._e()]}}],null,false,3640757484)}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }