<!-- eslint-disable no-restricted-syntax -->
<!-- eslint-disable no-restricted-syntax -->
<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col md="3">
        <h3> {{ title }} </h3>
      </b-col>

      <b-col md="9">
        <b-row
          class="justify-content-end"
          style="gap: 8px"
        >
          <b-button
            v-if="historic"
            style="width: 300px;"

            class="position-static"
            variant="primary"
            @click="() => $emit('click-historic')"
          >
            Histórico
          </b-button>

          <div
            ckass="position-relative"
            style="width: 300px;"
          >
            <b-button
              v-if="newType"
              class="position-relative w-100"
              variant="primary"
              :disabled="disabledButton"
              @click="handleClickNewButton"
            >
              {{ newTitle ? newTitle : buttonTitle }}
            </b-button>

            <div
              v-if="disabledButton"
              class="position-absolute"
            >
              <small> O horário para realizar essa ação é das {{ officeHours[0] }}h - {{ officeHours[1] }}h  </small>
            </div>
          </div>

        </b-row>
      </b-col>

    </b-row>

    <b-col
      class="p-0 mt-1"
      md="12"
    >
      <b-form-group
        label="Filtro"
        :label-for="`filter-${redirect}`"
      >
        <b-form-input
          :id="`filter-${redirect}`"
          v-model="filter"
          placeholder="Filtro"
        />
      </b-form-group>
    </b-col>

    <b-table
      responsive
      class="text-center mt-1 table-no-padding"
      :current-page="currentPage"
      :fields="fields"
      :items="tableData"
      :per-page="perPage"
      :filter="filter"
      hover
      @filtered="onFiltered"
    >

      <template #cell(liquidated_amount)="data">
        {{ data.item.liquidated_amount | value }}
      </template>

      <template #cell(unpaid_value)="data">
        {{ data.item.unpaid_value | value }}
      </template>

      <template #cell(contract_value)="data">
        {{ data.item.contract_value | value }}
      </template>

      <template #cell(total_amount)="data">
        {{ data.item.total_amount | value }}
      </template>

      <template #cell(committed_amount)="data">
        {{ data.item.committed_amount | value }}
      </template>

      <template #cell(uncommitted_amount)="data">
        {{ data.item.uncommitted_amount | value }}
      </template>
      <template #cell(id)="data">
        <span class="id-color"> {{ data.item.id }} </span>
      </template>

      <template #cell(reference_date)="data">
        {{ data.item.created_at | date }}
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | date }}
      </template>

      <template #cell(start_date)="data">
        {{ data.item.start_date | date }}
      </template>

      <template #cell(end_date)="data">
        {{ data.item.end_date | date }}
      </template>

      <template #cell(contract_due_date)="data">
        {{ data.item.contract_due_date | date }}
      </template>

      <template #cell(signature_date)="data">
        {{ data.item.signature_date | date }}
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="data.item.status_id === 1 ? 'secondary' : data.item.status_id === 2 ? 'success' : 'danger'"
        >
          {{ data.item.status_id === 1 ? 'Aguardando' : data.item.status_id === 2 ? 'Confirmado' : 'Negado' }}
        </b-badge>
      </template>

      <template #cell(effect)="data">
        <b-badge
          pill
          :variant="data.item.effect ? 'danger' : 'success'"
        >
          {{ data.item.effect ? "SIM" : "NÃO" }}
        </b-badge>
      </template>

      <template #cell(is_active)="data">
        <b-badge
          pill
          :variant="data.item.is_active ? 'success' : 'danger'"
        >
          {{ data.item.is_active ? "SIM" : "NÃO" }}
        </b-badge>
      </template>

      <template #cell(is_revoked_optin)="data">
        <b-badge
          pill
          :variant="data.item.is_revoked ? 'danger' : 'success'"
        >
          {{ data.item.is_revoked ? "SIM" : "NÃO" }}
        </b-badge>
      </template>

      <template #cell(is_revoked)="data">
        <b-badge
          pill
          :variant="data.item.is_revoked ? 'danger' : 'success'"
        >
          {{ data.item.is_revoked ? "SIM" : "NÃO" }}
        </b-badge>
      </template>

      <template #cell(register)="data">
        {{ data.item.register }}
      </template>

      <template #cell(status_endorsement)="data">
        <b-badge
          pill
          :variant="data.item.is_draft ? 'info' : data.item.status_id === 1 ? 'secondary' : data.item.status_id === 2 ? 'success' : 'danger'"
        >
          {{ data.item.is_draft ? 'Rascunho' : data.item.status_id === 1 ? 'Aguardando' : data.item.status_id === 2 ? 'Confirmado' : 'Negado' }}
        </b-badge>
      </template>

      <template #cell(has_contract_effects)="data">
        <has-contract-effect-field :has-contract-effects="!!data.item.has_contract_effects" />
      </template>

      <template
        v-if="details || edit"
        #cell(actions)="data"
      >
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>

          <b-dropdown-item
            v-if="details"
            @click="toDetails(data)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>Detalhes</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="edit"
            @click="toEdit(data.item.id, data.item.is_draft)"
          >

            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Editar</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="complement"
            @click="$router.push(`/plataforma-credito/optin/${data.item.id}/agenda`)"
          >

            <feather-icon
              icon="SquareIcon"
              class="mr-50"
            />
            <span>Agenda</span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>

    <p
      v-if="redirect === 'optin' && tableData.length === 0"
      class="text-center"
    >
      Crie um novo {{ redirect }}
    </p>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="5"
      align="center"
      size="sm"
      class="my-0"
    />
  </b-card>

</template>

<script>
import {
    BCard, BTable, BButton, BPagination, BRow, BCol, BDropdownItem, BDropdown, BBadge, BFormInput, BFormGroup, VBTooltip,
} from 'bootstrap-vue';
import { validateHours } from '@/utils/office-hours';
import HasContractEffectField from './HasContractEffectField.vue';

export default {
    components: {
        BButton,
        BCard,
        BTable,
        BPagination,
        BRow,
        BCol,
        BDropdownItem,
        BDropdown,
        BBadge,
        BFormInput,
        HasContractEffectField,
        BFormGroup,
    },

    directives: {
        'b-tooltip': VBTooltip,

    },

    props: {
        tableData: {
            type: Array,
            default: () => [],
        },

        fields: {
            type: Array,
            default: () => [],
        },

        title: {
            type: String,
            default: () => '',
        },

        edit: {
            type: Boolean,
            default: () => true,
        },

        details: {
            type: Boolean,
            default: () => true,
        },

        newType: {
            type: Boolean,
            default: () => true,
        },

        clickable: {
            type: Boolean,
            default: () => true,
        },

        buttonTitle: {
            type: String,
            required: true,
        },

        officeHours: {
            type: Array,
            default: () => false,
        },

        redirect: {
            type: String,
            required: true,
        },

        complement: {
            type: Boolean,
            default: () => false,
        },

        newHref: {
            type: String,
            default: () => '',
        },

        newTitle: {
            type: String,
            default: () => '',
        },

        historic: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return ({
            currentPage: 1,
            perPage: 5,
            filter: '',
            totalRows: this.tableData.length,
        });
    },

    computed: {
        disabledButton() {
            return !validateHours(this.officeHours);
        },
    },

    methods: {
        calculateCommiteddAmountConsult({ schedule: { settlementExpectations } }, key) {
            return (settlementExpectations.reduce((acc, item) => {
                // eslint-disable-next-line no-param-reassign
                acc += item[key];
                return acc;
            }, 0) / 100);
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        toEdit(id, isDraft) {
            const { redirect } = this;

            if (redirect !== 'consulta') { return this.$router.push(`/plataforma-credito/${redirect}${isDraft ? '/rascunho' : ''}/${id}`); }
            return this.$router.push(`/plataforma-credito/${redirect}/${id}`);
        },

        toDetails(id) {
            const { redirect } = this;
            if (redirect !== 'consulta') { return this.$router.push(`/plataforma-credito/${redirect}/detalhes/${id}`); }

            return this.$router.push({
                path: `/plataforma-credito/${redirect}/${id.item.id}`,
            });
        },

        handleClickNewButton() {
            const { redirect } = this;

            if (this.newHref) {
                this.$router.push(this.newHref);

                return;
            }
            // if (redirect !== 'consulta') {
            //     return this.$router.push(`/plataforma-credito/${redirect}/novo`);
            // }
            this.$router.push(`/plataforma-credito/${redirect}`);
        },
    },
};
</script>

  <style lang="scss" scoped>
  @import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

      .alert {
          padding: .2rem .7rem;
          width: 5.1rem;
          margin: 0;
          border-radius: 4rem;
          margin: auto
      }

        .id-color {
          color: $primary;
          font-weight: bold;
        }

.dark-layout {
  .id-color {
    color: $blue-500;
  }
}

  </style>

<style>
.btn.disabled {
pointer-events: auto !important;
}</style>
