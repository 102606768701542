<template>
  <b-badge
    pill
    :variant="hasContractEffects ? 'danger' : 'success'"
  >
    {{ hasContractEffects ? 'SIM' : 'NÃO' }}
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

export default {
    components: {
        BBadge,
    },

    props: {
        hasContractEffects: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
