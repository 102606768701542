<template>
  <b-card>
    <app-timeline v-if="items.length > 0">
      <app-timeline-item
        v-for="[index, item] of timeLineData.entries()"
        :key="index"
        :title="item.created_at | date"
        :subtitle="item.description"
        :variant="index%2 !== 0 ? 'info' : 'primary'"
      />
      <b-button
        class="d-block mx-auto mt-2"
        variant="primary"
        @click="handleClickShowMoreButton"
      >
        {{ showMore ? 'Mostrar Menos' : 'Mostrar Mais' }}
      </b-button>
    </app-timeline>

    <p
      v-else
      class="text-center"
    >
      Não possui histórico
    </p>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

import { BCard, BButton } from 'bootstrap-vue';

export default {
    components: {
        AppTimeline,
        AppTimelineItem,
        BCard,
        BButton,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            showMore: false,
        };
    },

    created() {
        this.items = this.items.reverse();
        this.timeLineData = this.items.slice(0, 5);
    },

    methods: {
        handleClickShowMoreButton() {
            this.showMore = !this.showMore;
            this.timeLineData = this.showMore ? this.items : this.items.slice(0, 5);
        },
    },
};
</script>

  <style scoped>
    .card{
        position: -webkit-sticky;
        position: sticky;
        top: 7rem;
    }
</style>
