<template>
  <div>
    <b-modal
      :ref="ref"
      scrollable
      size="lg"
      title="Histórico"
      hide-footer
    >
      <slot />
    </b-modal>
  </div>
</template>

<script>
import {
    BModal,
} from 'bootstrap-vue';

export default {
    components: {
        BModal,
    },

    data() {
        return {
            ref: 'table-modal',
        };
    },

    methods: {
        openModal() {
            const reference = this.$refs[this.ref];

            reference.show();
        },

    },
};
</script>
