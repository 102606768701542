<template>
  <div>
    <Table
      :table-data="data"
      :office-hours="officeHour"
      :fields=" [
        { key: 'id', label: 'ID' },
        { key: 'register', label: 'Registradora' },
        { key: 'created_at', label: 'Data Criação' },
        { key: 'start_date', label: 'Data Início' },
        { key: 'end_date', label: 'Data Final' },
        { key: 'is_revoked_optin', label: 'Revogado' },
        { key: 'is_active', label: 'Ativo' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Ações' },
      ]"
      :edit="true"
      :details="false"
      :historic="true"
      redirect="optin"
      button-title="Novo Opt-In"
      :complement="true"
      title="Opt-Ins"
      @click-historic="getHistoric"
    />

    <Historic

      ref="historic"
    >
      <div v-if="records">
        <b-table
          hover
          class="text-center historic-table"
          :fields="[
            'id',
            { key: 'register', label: 'Registradora'},
            { key: 'updated_at', label: 'Última Atualização'}
          ]"
          :items="records.data"

          @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
        >
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | date }}
          </template>

          <template
            slot="row-details"
            slot-scope="row"
          >

            <b-table
              :fields="[
                { label: 'Usuário' ,key: 'user', },
                { label: 'Ação', key: 'action_type'},
                { label: 'Quando', key: 'created_at'}
              ]"
              :items="row.item.opt_in_records"
              @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            >
              <template #cell(user)="data">
                {{ data.item.user.name }}
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | date }}
              </template>

              <template
                slot="row-details"
                slot-scope="row"
              >
                <b-row class="justify-content-around">
                  <div v-if="row.item.start_date">

                    <h6

                      class="text-left"
                    >
                      Data Inicial:
                    </h6>

                    <p> {{ row.item.start_date | date }}</p>
                  </div>

                  <div v-if="row.item.end_date">

                    <h6

                      class="text-left"
                    >
                      Data Final:
                    </h6>

                    <p>
                      {{ row.item.end_date | date }}
                    </p>
                  </div>

                  <div v-if="row.item.is_revoked">

                    <h6
                      class="text-left"
                    >
                      Revogado:
                    </h6>

                    <p>{{ row.item.is_revoked ? 'Sim' : 'Não' }}</p>

                  </div>
                </b-row>
              </template>
            </b-table>
          </template>
        </b-table>

        <b-pagination
          v-if="records.meta"
          v-model="page"
          :total-rows="records.meta.total"
          :per-page="records.meta.per_page"
          align="center"
          size="sm"
          class="mt-2"
        />
      </div>
    </Historic>
  </div>
</template>

<script>
import { BTable, BRow, BPagination } from 'bootstrap-vue';
import Table from '../Table.vue';

import RecordService from '@/services/osiris/RecordsService';

import Historic from '../../../../Historic/Historic.vue';
import { officeHours } from '@/utils/office-hours';

export default {
    components: {
        Table,
        Historic,
        BTable,
        BRow,
        BPagination,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },

        merchantId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            cache: {},
            records: null,
            page: 1,
            officeHour: officeHours.optin,
        };
    },

    watch: {
        page() {
            this.getHistoricData();
        },
    },

    methods: {
        async getHistoricData() {
            if (this.cache[this.page]) {
                this.records = this.cache[this.page];

                return;
            }
            this.isLoading(true);
            const { data } = await RecordService.getOptIn({
                merchantId: this.merchantId,
                page: this.page,
            });

            this.cache[this.page] = data;
            this.isLoading(false);

            this.records = data;
        },
        async getHistoric() {
            this.$refs.historic.openModal();

            if (!this.records) this.getHistoricData();
        },
    },
};
</script>

<style>
.historic-table thead tr {
    th {
        width: 200px;
    }
}
</style>
