<template>
  <div>
    <Table
      :new-type="true"
      :table-data="data"
      :fields="[
        { key: 'id', label: 'ID' },
        { key: 'register', label: 'Registradora' },
        { key: 'signature_date', label: 'Assinatura' },
        { key: 'created_at', label: 'averbação' },
        { key: 'contract_due_date', label: 'Vencimento' },
        { key: 'liquidated_value', label: 'Recebido' },
        { key: 'unpaid_value', label: 'Receber' },
        { key: 'contract_value', label: 'Total' },
        { key: 'is_revoked', label: 'Revogado' },
        { key: 'status_endorsement', label: 'Status' },
        { key: 'actions', label: 'Ações' },
      ]"
      :edit="true"
      :details="false"
      redirect="averbacao"
      :office-hours="officeHour"
      title="Contratos"
      button-title="Nova Averbação"
      draft
      historic
      @click-historic="getHistoric"
    />

    <Historic

      ref="historic"
    >

      <div v-if="records">
        <b-table

          hover
          class="text-center historic-table"
          :fields="[
            'id',
            { key: 'register', label: 'Registradora'},
            { key: 'updated_at', label: 'Última Atualização'}
          ]"
          :items="records.data"

          @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
        >
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | date }}
          </template>

          <template #cell(register)="data">
            {{ data.item.opt_in.register }}
          </template>

          <template
            slot="row-details"
            slot-scope="rowDetails"
          >

            <b-table
              :fields="[
                { label: 'Usuário' ,key: 'user', },
                { label: 'Ação', key: 'action_type'},
                { label: 'Quando', key: 'created_at'}
              ]"
              :items="rowDetails.item.endorsement_records"
              @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
            >
              <template #cell(user)="data">
                {{ data.item.user.name }}
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | date }}
              </template>

              <template
                slot="row-details"
                slot-scope="row"
              >
                <b-row
                  class="justify-content-around"
                >
                  <div>

                    <h6>
                      Quantidade de URs Adicionadas:
                    </h6>

                    <p> {{ row.item.specifications_amount }}</p>
                  </div>

                </b-row>

                <b-row
                  v-if="rowDetails.item.endorsement_records[row.index - 1] ? rowDetails.item.endorsement_records[row.index - 1].account_id !== row.item.account_id : true"
                  class="justify-content-around"
                >
                  <b-col md="4">
                    <h6>
                      Banco:
                    </h6>

                    <p> {{ row.item.account.isbp.name }}</p>
                  </b-col>

                  <b-col md="4">
                    <h6>
                      Agência:
                    </h6>

                    <p> {{ row.item.account.agency }}</p>
                  </b-col>

                  <b-col md="4">
                    <h6>
                      Conta:
                    </h6>

                    <p> {{ row.item.account.account_number + '-' + row.item.account.account_digit }}</p>
                  </b-col>
                  <b-col md="4">
                    <h6>
                      Titular da Conta:
                    </h6>

                    <p> {{ row.item.account.holder_name }}</p>
                  </b-col>

                  <b-col md="4">
                    <h6>
                      Tipo do Documento:
                    </h6>

                    <p> {{ row.item.account.document_type }}</p>
                  </b-col>

                  <b-col md="4">
                    <h6>
                      Documento:
                    </h6>

                    <p> {{ row.item.account.document_number | tax_id }}</p>
                  </b-col>

                </b-row>

              </template>
            </b-table>
          </template>
        </b-table>

        <b-pagination
          v-if="records.meta"
          v-model="page"
          :total-rows="records.meta.total"
          :per-page="records.meta.per_page"
          align="center"
          size="sm"
          class="mt-2"
        />
      </div>
    </Historic>
  </div>
</template>

<script>
import {
    BTable, BRow, BPagination, BCol,
} from 'bootstrap-vue';
import Table from '../Table.vue';

import RecordService from '@/services/osiris/RecordsService';

import Historic from '../../../../Historic/Historic.vue';

import { officeHours } from '@/utils/office-hours';

export default {
    components: {
        Table,
        Historic,
        BTable,
        BRow,
        BPagination,
        BCol,
    },

    props: {
        data: {
            type: Array,
            required: true,
        },

        merchantId: {
            type: Number,
            required: true,
        },

        hasValideOptIn: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            records: null,
            page: 1,
            cache: {},
            officeHour: officeHours.endorsement,
        };
    },

    watch: {
        page() {
            this.getHistoricData();
        },
    },

    methods: {
        // eslint-disable-next-line consistent-return
        async getHistoricData() {
            this.records = await this.request();
        },
        async getHistoric() {
            this.$refs.historic.openModal();

            if (!this.records) this.getHistoricData();
        },

        async request() {
            if (this.cache[this.page]) {
                return this.cache[this.page];
            }
            this.isLoading(true);
            const { data } = await RecordService.getEndorsement({
                merchantId: this.merchantId,
                page: this.page,
            });

            this.cache[this.page] = data;
            this.isLoading(false);
            return data;
        },
    },
};
</script>

<style>
.historic-table thead tr {
    th {
        width: 200px;
    }
}
</style>
