var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"new-type":true,"table-data":_vm.data,"fields":[
      { key: 'id', label: 'ID' },
      { key: 'register', label: 'Registradora' },
      { key: 'signature_date', label: 'Assinatura' },
      { key: 'created_at', label: 'averbação' },
      { key: 'contract_due_date', label: 'Vencimento' },
      { key: 'liquidated_value', label: 'Recebido' },
      { key: 'unpaid_value', label: 'Receber' },
      { key: 'contract_value', label: 'Total' },
      { key: 'is_revoked', label: 'Revogado' },
      { key: 'status_endorsement', label: 'Status' },
      { key: 'actions', label: 'Ações' } ],"edit":true,"details":false,"redirect":"averbacao","office-hours":_vm.officeHour,"title":"Contratos","button-title":"Nova Averbação","draft":"","historic":""},on:{"click-historic":_vm.getHistoric}}),_c('Historic',{ref:"historic"},[(_vm.records)?_c('div',[_c('b-table',{staticClass:"text-center historic-table",attrs:{"hover":"","fields":[
          'id',
          { key: 'register', label: 'Registradora'},
          { key: 'updated_at', label: 'Última Atualização'}
        ],"items":_vm.records.data},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(updated_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.updated_at))+" ")]}},{key:"cell(register)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.opt_in.register)+" ")]}},{key:"row-details",fn:function(rowDetails){return [_c('b-table',{attrs:{"fields":[
              { label: 'Usuário' ,key: 'user', },
              { label: 'Ação', key: 'action_type'},
              { label: 'Quando', key: 'created_at'}
            ],"items":rowDetails.item.endorsement_records},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(user)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user.name)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.created_at))+" ")]}},{key:"row-details",fn:function(row){return [_c('b-row',{staticClass:"justify-content-around"},[_c('div',[_c('h6',[_vm._v(" Quantidade de URs Adicionadas: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.specifications_amount))])])]),(rowDetails.item.endorsement_records[row.index - 1] ? rowDetails.item.endorsement_records[row.index - 1].account_id !== row.item.account_id : true)?_c('b-row',{staticClass:"justify-content-around"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Banco: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.account.isbp.name))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Agência: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.account.agency))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Conta: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.account.account_number + '-' + row.item.account.account_digit))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Titular da Conta: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.account.holder_name))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Tipo do Documento: ")]),_c('p',[_vm._v(" "+_vm._s(row.item.account.document_type))])]),_c('b-col',{attrs:{"md":"4"}},[_c('h6',[_vm._v(" Documento: ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("tax_id")(row.item.account.document_number)))])])],1):_vm._e()]}}],null,true)})]}}],null,false,3753350006)}),(_vm.records.meta)?_c('b-pagination',{staticClass:"mt-2",attrs:{"total-rows":_vm.records.meta.total,"per-page":_vm.records.meta.per_page,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }