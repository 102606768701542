import OsirisService from './BaseOsirisService';

class RecordService extends OsirisService {
    async getOptIn({
        merchantId,
        page = 1,
        pageSize = 5,
    }) {
        try {
            return await this.api.get('/record/opt-in', {
                params: {
                    page,
                    merchant_id: merchantId,
                    page_size: pageSize,
                },
            });
        } catch ({ response }) {
            return response;
        }
    }

    async getEndorsement({
        merchantId,
        page = 1,
        pageSize = 5,
    }) {
        try {
            return await this.api.get('/record/endorsement', {
                params: {
                    page,
                    merchant_id: merchantId,
                    page_size: pageSize,
                },
            });
        } catch ({ response }) {
            return response;
        }
    }

    async getMerchant({
        merchantId,
        page = 1,
        pageSize = 5,
    }) {
        try {
            return await this.api.get('/record/merchant', {
                params: {
                    page,
                    merchant_id: merchantId,
                    page_size: pageSize,
                },
            });
        } catch ({ response }) {
            return response;
        }
    }
}

export default new RecordService();
