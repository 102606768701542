<template>
  <b-container
    v-if="showPage"
    fluid
  >
    <BreadCrumb
      :items="[
        {
          text: 'Inicio',
          href: '/'
        }, {
          text: 'Detalhes Estabalecimento',
          active: false,
        }]"
    />

    <Header
      :merchant-data="{
        tax_id: fetchData.tax_id,
        name: fetchData.legal_tax_name,
        active: fetchData.is_active
      }"
      @click-historic-button="getHistoric"
    />

    <b-row>

      <b-col
        md="7"
        lg="9"
      >

        <OptIn
          :data="optInsItems"
          :merchant-id="+id.id"
        />

        <Endorsement
          :data="endorsementsItems"
          :merchant-id="+id.id"
          :has-valide-opt-in="hasValidOptiIn"
        />
      </b-col>

      <b-col
        md="5"
        lg="3"
      >
        <time-line :items="fetchData.time_line" />
      </b-col>
    </b-row>

    <Historic ref="historic">
      <b-table
        v-if="records"
        class="text-center"
        :items="records.data"
        :fields="[
          { key: 'user', label: 'Usuário'},
          { key: 'action_type', label: 'Ação'},
          { key: 'created_at', label: 'Quando'}
        ]"
        @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
      >
        <template #cell(user)="data">
          {{ data.item.user.name }}
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | date }}
        </template>

        <template
          slot="row-details"
          slot-scope="row"
        >
          <b-row
            v-if="row.item.action_type !== 'Inativação de estabelecimento'"
            class="justify-content-around"
          >
            <b-col md="4">
              <div>
                <h6> Email: </h6>
                <p>  {{ row.item.email }}</p>
              </div>

            </b-col>

            <b-col md="4">
              <h6> Razão Social: </h6>
              <p>  {{ row.item.legal_tax_name }}</p>
            </b-col>

            <b-col md="4">
              <h6> Nome: </h6>
              <p>  {{ row.item.name }}</p>
            </b-col>

            <b-col md="4">
              <h6> CNPJ: </h6>
              <p>  {{ row.item.tax_id | tax_id }}</p>
            </b-col>

          </b-row>

        </template>
      </b-table>
    </Historic>

  </b-container>
</template>

<script>
import {
    BContainer, BRow, BCol, BTable,
} from 'bootstrap-vue';
import MerchantService from '@/services/osiris/MerchantService';

import Header from './components/Header.vue';
import Historic from '../../Historic/Historic.vue';
import TimeLine from './components/TimeLine.vue';
import { Crypto } from '@/libs/crypto';
import BreadCrumb from '@/views/components/BreadCrumb.vue';

import OptIn from './components/OptIn/OptIn.vue';

import Endorsement from './components/Endorsement/Endorsement.vue';

import RecordsService from '@/services/osiris/RecordsService';

export default {
    components: {
        Header,
        Historic,
        BContainer,
        Endorsement,
        BRow,
        BCol,
        TimeLine,
        BreadCrumb,
        OptIn,
        BTable,
    },
    data() {
        return {
            id: this.$route.params,
            fetchData: {
                merchant: {},
                opt_ins: [],
                consult: [],
                averbation: [],
                time_line: [],
            },
            showPage: false,
            records: null,
        };
    },

    computed: {
        hasValidOptiIn() {
            return (!!this.fetchData.opt_ins.find(item => item.is_active === 1 && item.status_id === 2));
        },

        endorsementsItems() {
            const { endorsements } = this.fetchData;
            return endorsements.reverse();
        },

        optInsItems() {
            const { opt_ins: optIns } = this.fetchData;
            return optIns.reverse();
        },
    },

    created() {
        this.getMerchant();
    },

    methods: {
        async getMerchant() {
            this.isLoading(true);
            const { data, status } = await MerchantService.show(this.id);
            if (status !== 200) {
                this.$router.push('/error-404');
            }

            data.endorsements = data.endorsements.map(endorsement => {
                const fondedOptIn = data.opt_ins.find(optIn => endorsement.opt_in_id === optIn.id);

                const { register } = fondedOptIn;

                return {
                    ...endorsement,
                    register,
                };
            });

            this.fetchData = data;

            const optInsTagActive = data.opt_ins.filter(item => item.register === 'TAG' && item.is_active && item.status_id === 2);
            const tagOptInId = optInsTagActive[optInsTagActive.length - 1]?.id;

            const optInsCercActive = data.opt_ins.filter(item => item.register === 'CERC' && item.is_active && item.status_id === 2);
            const cercOptInId = optInsCercActive[optInsCercActive.length - 1]?.id;

            this.haveOptInActive = tagOptInId || cercOptInId;

            this.showPage = true;
            const merchantData = {
                id: data.id,
                asset_holder: data.tax_id,
                legal_tax_name: data.legal_tax_name,
                optin_id: {
                    tag: tagOptInId,
                    cerc: cercOptInId,
                },
            };

            this.$store.commit('creditPlatform/UPDATE_SELECTED_MERCHANT', merchantData);
            sessionStorage.setItem('optin', Crypto.encrypt(JSON.stringify(merchantData)));

            this.isLoading(false);
        },

        async getHistoricData() {
            this.isLoading(true);
            const { data } = await RecordsService.getMerchant({
                merchantId: this.id.id,
                page: this.page,
            });
            this.isLoading(false);

            this.records = data;
        },
        async getHistoric() {
            this.$refs.historic.openModal();

            if (!this.records) this.getHistoricData();
        },

    },
};
</script>
