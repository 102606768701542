export const officeHours = {
    optin: [9, 21],
    endorsement: [9, 19],
    consult: [9, 23],
};

export const validateHours = ([start, end]) => {
    const brazilTime = new Date().toLocaleTimeString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    });

    const [currentHour] = brazilTime.split(':').map(Number);
    return currentHour >= start && currentHour <= end;
};
