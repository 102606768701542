var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"table-data":_vm.data,"office-hours":_vm.officeHour,"fields":[
      { key: 'id', label: 'ID' },
      { key: 'register', label: 'Registradora' },
      { key: 'created_at', label: 'Data Criação' },
      { key: 'start_date', label: 'Data Início' },
      { key: 'end_date', label: 'Data Final' },
      { key: 'is_revoked_optin', label: 'Revogado' },
      { key: 'is_active', label: 'Ativo' },
      { key: 'status', label: 'Status' },
      { key: 'actions', label: 'Ações' } ],"edit":true,"details":false,"historic":true,"redirect":"optin","button-title":"Novo Opt-In","complement":true,"title":"Opt-Ins"},on:{"click-historic":_vm.getHistoric}}),_c('Historic',{ref:"historic"},[(_vm.records)?_c('div',[_c('b-table',{staticClass:"text-center historic-table",attrs:{"hover":"","fields":[
          'id',
          { key: 'register', label: 'Registradora'},
          { key: 'updated_at', label: 'Última Atualização'}
        ],"items":_vm.records.data},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(updated_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.updated_at))+" ")]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"fields":[
              { label: 'Usuário' ,key: 'user', },
              { label: 'Ação', key: 'action_type'},
              { label: 'Quando', key: 'created_at'}
            ],"items":row.item.opt_in_records},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(user)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user.name)+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.created_at))+" ")]}},{key:"row-details",fn:function(row){return [_c('b-row',{staticClass:"justify-content-around"},[(row.item.start_date)?_c('div',[_c('h6',{staticClass:"text-left"},[_vm._v(" Data Inicial: ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(row.item.start_date)))])]):_vm._e(),(row.item.end_date)?_c('div',[_c('h6',{staticClass:"text-left"},[_vm._v(" Data Final: ")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(row.item.end_date))+" ")])]):_vm._e(),(row.item.is_revoked)?_c('div',[_c('h6',{staticClass:"text-left"},[_vm._v(" Revogado: ")]),_c('p',[_vm._v(_vm._s(row.item.is_revoked ? 'Sim' : 'Não'))])]):_vm._e()])]}}],null,true)})]}}],null,false,2734730316)}),(_vm.records.meta)?_c('b-pagination',{staticClass:"mt-2",attrs:{"total-rows":_vm.records.meta.total,"per-page":_vm.records.meta.per_page,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }